// Used for showofhands poll to compute predictible common index
// based on ID

// propositions = array of proposition objects
// voteId = number | string | { [id]: boolean } | [number | string]
exports.computeVoteIndex = (_, propositions) => {
  const propIds = _.map(propositions, p => String(p.id));

  const scalarIndex = id => {
    const i = propIds.indexOf(String(id));
    if (i === -1) return '';
    return String(i + 1);
  };

  const anyIndex = (input, joinChar = ', ') => {
    // Array: iterate as scalars
    if (_.isArray(input)) {
      return _.map(input, scalarIndex).join(joinChar);
    }
    // Object: Keep only keys for truthy values and convert to array
    if (_.isObject(input)) {
      const ids = _.filter(Object.keys(input), v => !!input[v]);
      return anyIndex(ids, joinChar);
    }
    // Otherwise: scalar
    return scalarIndex(input);
  };

  return anyIndex;
};