'use strict';

export default ({ speed = 500, delay = 500, duration = 2000 } = {}) => {
  const processing = 'processing';

  // Internal state
  let $this, $progress, $done; // DOM elements initialized on start()
  let progressValue = 0;       // Progress from 0 to 100
  let progressTimer = null;    // Timeout

  const tick = () => {
    progressValue += (80 - progressValue) / 3;
    $progress.css({ width: progressValue + '%' });
    progressTimer = setTimeout(tick, speed / 2);
  };

  const start = $button => {
    $this = $button;
    $this.blur();
    $this.addClass(processing); // Avoid multiple clicks on trigger
    $progress = $this.find('.ui-btnProgress-bar');
    $done = $this.find('.ui-btnProgress-done');
    progressTimer = null;
    $progress.css({ transition: 'width ' + (speed / 1000) + 's', width: '0%' });
    progressValue = 0;
    tick();
  };

  const stop = (success, onAnimationFinished, resetDelay) => {
    if (success) {
      $progress.css({ width: '100%' });
      resetDelay = speed;

      // TODO: Check if code below is correct and at the right place
      setTimeout(() => {
        // Once progress is completed, show done message
        $done.animate({opacity: 1}, delay, () => {
          // Once done message has been displayed, reset progress
          reset();
          // Then remove done message
          setTimeout(() => {
            // Button triggerable again
            $done.animate({opacity: 0}, delay, () => $this.removeClass(processing));
          }, duration);
        });
      }, speed);
      // end check

    } else if (resetDelay === undefined) {
      resetDelay = speed;
    }
    clearTimeout(progressTimer);
    // TODO: Check
    // Don't know how to manage this if no success,
    // progress bar should be hidden only after done msg has been displayed
    // setTimeout(reset, resetDelay);
    if (onAnimationFinished) {
      setTimeout(onAnimationFinished, resetDelay);
    }
  };

  const reset = () => {
    $progress.hide();
    $progress.css({ width: '0%' });
    setTimeout(function () {
      $progress.show();
    }, speed);
  };

  // Public API
  return { start, stop };
};