"use strict";

// Check if local storage really works. Workaround for iOS Safari in private mode:
// Safari can state local storage works, however it throws an error on setItem()
// as the quota for local storage is set to 0
function isLocalStorageSupported() {
  var testKey = 'lstest';
  try {
    window.localStorage.setItem(testKey, '1');
    window.localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}
// As we may have some restrictions on localStorage in private mode of some browsers (in Safari, it's readonly),
// we will create a new object instead of trying manipulating (overloading, replacing, deleting) the original one
// https://gist.github.com/jarrodirwin/0ce4c0888336b533b2c4, please read comments as well
// Let's define our _localStorage object: a copy of localStorage or a new empty object
let _localStorage = isLocalStorageSupported() && window.localStorage; // If local storage works we can use it as is...
if (!_localStorage) { // ... otherwise use memory. In future, we may use cookies here
  let data = {};
  const _localStorage = {};
  _localStorage.getItem = k => (data[k] || null);
  _localStorage.setItem = (k, v) => { data[k] = String(v); };
  _localStorage.removeItem = k => { delete data[k]; };
  _localStorage.clear = () => { data = {}; };
  module.exports = _localStorage;
}

export default _localStorage;
