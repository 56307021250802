'use strict';

import $ from 'jquery';

// ---------------------------------------------------- //
// TOOLTIPS
// ---------------------------------------------------- //
$(document).on('click', '.ui-tooltip-trigger', function() {

    // Vars
    var $wrapper = $(this).closest('.ui-tooltip');

    // Toggle
    $wrapper.toggleClass('active');

    // Prevent page from reloading
    return false;
});