'use strict';

import $ from 'jquery';

const ui_tabs = () => {

  const wrapper = '.ui-tabs',
        trigger = '.ui-tabs-trigger',
        content = '.ui-tabs-content',
        active = 'active';

  $(document).on('click', trigger + ':not(.' + active + ')', function() {
    const $this = $(this);
    $this.blur(); // fix accessibility focus
    triggerActiveTab($this);
    return false;
  });

  const triggerActiveTab = targetOr$el => {
    const $el = typeof targetOr$el === 'string'
      ? $(trigger + '[href="' + targetOr$el + '"]')
      : targetOr$el;

    // Vars
    const target = $el.attr('href');
    let $tabs_wrapper = $el.closest(wrapper);

    // Manage menu
    $tabs_wrapper.find(trigger + '.' + active).removeClass(active);
    $el.addClass(active);

    // Manage wrapper
    if ($tabs_wrapper.attr('data-tabs-content')) {
      $tabs_wrapper = $($tabs_wrapper.attr('data-tabs-content'));
    }

    // Manage tabs
    $tabs_wrapper.find(content + '.' + active).removeClass(active);
    $(target).addClass(active);

    // Trigger event to notify outside
    $tabs_wrapper.trigger('ui-tabs:change', [target]);
  };

  // TODO:
  // - Don't recover active container using active trigger's href, recover directly the active content
  // - Use global 'active' variable
  const getActive = $wrapper => {
    const $active = $(trigger + '.active', $wrapper);
    return $active[0] && $active[0].getAttribute('href');
  };

  return {
    getActive,
    trigger: triggerActiveTab,
    selectors: { wrapper, trigger, content },
  };
};

export default {
  init: ui_tabs
};