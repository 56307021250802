'use strict';

import $ from 'jquery';

$.fn.expander = function(options) {

   // Global
   var selectorClass = 'ui-expander',
       selector = '.' + selectorClass,
       s_content = '-content',
       s_inner = '-inner',
       s_trigger = '-trigger',
       c_ellipsis = selectorClass + '--ellipsis';

   /*** Each element ***/
   return this.each(function(){

      // Merge default with data attributes and user options
      var $this = $(this),
          opts = $.extend({
            linescollapsed: 1, // Lines when collapsed
            linesmin: 5, // Lines required to init
            speed: 500,
            wrappertag: 'div',
            triggertag: 'span',
            active: 'collapsed',
            showmore: 'Agrandir ▼',
            showless: 'Réduire ▲',
            ellipsis: false
          }, options, $this.data());

      // Init objects
      var $content = $('<' + opts.wrappertag + ' class="' + selectorClass + s_content + '"></' + opts.wrappertag + '>'),
          $inner = $('<' + opts.wrappertag + ' class="' + selectorClass + s_inner + '"></' + opts.wrappertag + '>'),
          $trigger = $('<' + opts.triggertag + ' class="' + selectorClass + s_trigger + '" aria-hidden="true" tabindex="-1"></' + opts.triggertag + '>');

      /*** DOM build ***/

      $this
         .addClass(selectorClass) // Add expander class
         .wrapInner(function() { // Build content wrapper
            $content.css({
               'position': 'absolute',
               'top': 0,
               'left': 0,
               'width': '100%'
            });
            return $content;
         })
         .wrapInner(function() { // Build inner wrapper
            $inner
               .css({
                  'position': 'relative',
                  'overflow': 'hidden'
               });
            return $inner;
         });

      /*** Manage inner height ***/

      // Vars
      var $this_content = $this.find(selector + s_content),
          $this_inner = $this.find(selector + s_inner),
          contentHeightExpanded,
          contentHeightCollapsed,
          enoughLines;

      // [function] Manage inner height
      var manageInnerHeight = function() {

         // Vars
         var contentLineHeight = parseFloat($this_content.css('line-height')), // line-height might change according to breakpoint
             contentLines;

         // Maths
         contentHeightExpanded = $this_content.height();
         contentLines = Math.round(contentHeightExpanded / contentLineHeight);
         contentHeightCollapsed = contentLineHeight * opts.linescollapsed;
         enoughLines = contentLines >= opts.linesmin;

         // Height to apply based on state and min lines required
         var innerHeight = $this.hasClass(opts.active) && enoughLines ? contentHeightCollapsed : contentHeightExpanded;

         // Apply height
         $this_inner.height(innerHeight);
      };

      // Ready
      manageInnerHeight();

      /*** Manage active at start ***/

      var hasEllipsis = opts.ellipsis;

      if( $this.hasClass(opts.active) ) {
         $trigger.html(opts.showmore); // active state = show more trigger
         $this.css('height', 'auto'); // reset height in case it has been fixed with css

         if(enoughLines && hasEllipsis) { // add ellipsis if enough lines
            $this.addClass(c_ellipsis);
         }
      }
      else {
         $trigger.html(opts.showless); // inactive = show less trigger
      }

      /*** Manage trigger ***/

      // [function] Hide trigger if no enough lines
      var manageTrigger = function(trigger) {
         enoughLines ? trigger.css('display', '') : trigger.hide();
      };

      // Ready
      manageTrigger($trigger);

      // Build trigger
      $this.append($trigger);
      var $this_trigger = $this.find(selector + s_trigger);

      /*** Manage toggle on click ***/

      // Attach click event
      $this_trigger.on('click', function() {

         // Vars
         var $trigger = $(this),
             isActive = $this.hasClass(opts.active),
             heightUpdate, triggerUpdate;

         // Manage height, trigger and ellipsis
         if( isActive ) {
            heightUpdate = contentHeightExpanded;
            triggerUpdate = opts.showless;
            if(hasEllipsis) $this.removeClass(c_ellipsis);
         }
         else {
            heightUpdate = contentHeightCollapsed;
            triggerUpdate = opts.showmore;
         }

         // Apply new height
         $this_inner.animate({ 'height': heightUpdate}, opts.speed, function() {

            // Toggle active class
            $this.toggleClass(opts.active);

            // Toggle trigger
            $trigger.html(triggerUpdate);

            // Toggle ellipsis
            if(hasEllipsis && !isActive) $this.addClass(c_ellipsis);
         });

      });

      /*** Manage resize ***/

      $(window).on('resize', function(){

         // Remove ellipsis (if there is one) while processing
         if(hasEllipsis && $this.hasClass(opts.active)) {
            $this.removeClass(c_ellipsis);
            manageInnerHeight();
            if(enoughLines) $this.addClass(c_ellipsis);
         }
         else {
            manageInnerHeight();
         }

         // Manage trigger
         manageTrigger($this_trigger);
      });

   });
};