export const getTerminalConfig = () => ({
  screen : {
    availHeight: screen.availHeight,
    availWidth: screen.availWidth,
    colorDepth: screen.colorDepth,
    height: screen.height,
    pixelDepth: screen.pixelDepth,
    width: screen.width,
    pixelDensity: window.devicePixelRatio * 96,
  },
  browser: {
    appName: navigator.appName,
    appVersion: navigator.appVersion,
    cookieEnabled: navigator.cookieEnabled,
    platform: navigator.platform,
    userAgent: navigator.userAgent,
  },
  window: {
    innerHeight: typeof window.innerHeight != 'undefined' ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : document.body.clientHeight,
    innerWidth: typeof window.innerWidth != 'undefined' ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : document.body.clientWidth
  },
});
