'use strict';

import $ from 'jquery';
import { computeVoteIndex } from '../../lib/poll-vote-index';
import buttonProgressingBuilder from './libs/button-progressing';
import confirmDisconnect from './libs/confirm-disconnect';
import { _alert, _confirm, _toast, _alertWithID, closeAlert } from './libs/dialogs';
import { broadcastorFlag } from './libs/gauges';
import './libs/jquery.expander';
import './libs/jquery.simpleAccordion';
import _localStorage from './libs/local-storage.js';
import _ from './libs/lodash';
import { updatePollStatusFilterLabels } from './libs/poll-status-filter-label';
import './libs/jquery.magnific-popup';
import { magnificpopup_inline_defaults } from './libs/magnificpopup-helpers';
import { socketConnect } from './libs/socket';
import { fillPollStyleParams, getLogoConfig } from './libs/style-params';
import ui_tabs from './libs/tabs';
import { getTerminalConfig } from './libs/terminal-config';
import './libs/ui-tooltip';
import { watchTheme } from './libs/watch-theme';
import { maskEmail } from './libs/masks';


/* eslint no-underscore-dangle:0, no-alert:0, quotes:0, indent:0 */
/* eslint-env browser */

const buttonProgressing = buttonProgressingBuilder();

// Procurations tabs
// eslint-disable-next-line no-unused-vars
const tabs = ui_tabs.init();

// Get voter container
const getVoterContainer = () => {
  return $('.js-voterContainer.active');
};

// Get voter id
const getVoterId = () => {
  const $voterContainer = getVoterContainer(),
        voterId = $voterContainer.attr('data-voter-id');
  return voterId ? voterId : window.USER_ID;
};

// Preview mode?
const preview = !!window.location.href.match(/(?:\?|&)preview(?:=1)?(?:&|#|$)/);
const fakeSocket = {
  connected: true,
  io: {
     on: (/* event, handler */) => { /* noop */ } // socket.io.on('reconnect'...) needs to be defined
    },
  on: (/* event, handler */) => { /* noop */ },
  emit: (/* event, ...params */) => { /* noop */ },
  off: () => { /* noop */ },
  close: () => { /* noop */ },
  connect: () => { /* noop */ },
  disconnect: () => { /* noop */ },
  onConfirmed: () => { /* noop */ },
  emitConfirmed: () => { /* noop */ },
};
if (preview) {
  // Make poll
  window._previewPoll = showHidePoll;
}


// Connect websocket
const socket = preview ? fakeSocket : socketConnect('participant');

// Enable js-confirmDisconnect
confirmDisconnect(socket);

// Inject theme's logo
const logoTemplate = _.template($('#logo-template').text());
const updateLogo = () => {
  $('#session-logo').remove();
  $('#session-title').before(logoTemplate({ logo: getLogoConfig(), theme_name: window.THEME_CONFIG.name }));
};
updateLogo();

// Connected: watch theme update in real time
watchTheme(socket, () => {
  // When theme changes, if there is an active poll, reload its poll style params
  if (currentPoll) {
    showHidePoll(currentPoll);
  }
  // Refresh logo
  updateLogo();
});

// Received ghosts disconnection notification
socket.on("participant:duplicates", function (ids) {
  window.console && console.log("Duplicate connections:", ids); // eslint-disable-line no-console
});

/**
 * @param string reason description of why I am disconnected by server
 * @param string? socketId duplicate socket id (only if reason = 'duplicate')
 * @param boolean? sameBrowser are the duplicate sockets on same browsers (tabs) (only if reason = 'duplicate')
 */
socket.on("participant:disconnect", function (reason, socketId, sameBrowser) {
  // Prevent reconnection
  socket.off("disconnect");
  // Force-disconnect client-side
  socket.close();
  // Quit participant UI (logs out if not same browser)
  quitSession(reason, reason === 'duplicate' && !sameBrowser);
});

// Specific connection error: session is off
socket.on('error', reason => {
  if (reason === 'SESSION_OFF') {
    quitSession('complete');
  } // SESSION_PREPARE handled by "socket.js": dinstinguishing "I cannot connect" than "I have been disconnected"
});

function quitSession (reason, logout = false) {
  // Redirect to event home
  const redirect = () => {
    const url = window.EVENT.personal ? window.URL_QUESTION_DIRECT : window.EVENT.url;
    const fullUrl = logout ? window.URL_LOGOUT + '?redirect=' + encodeURIComponent(url) : url;
    document.location.href = fullUrl;
  };
  const msgKey = reason === 'complete'
    ? 'sessionOffDisconnect'
    : reason === 'prepare'
    ? 'sessionPrepareDisconnect'
    : reason === 'live'
    ? 'sessionLiveDisconnect'
    : reason === 'duplicate'
    ? 'disconnectDuplicate'
    : 'otherDisconnect';
  const titleKey = msgKey + 'Title';
  window.SKIP_DISCONNECTION_WARNING_TOAST = true; // Do not show socket disconnection alert, we already have this message (see gauges.js)
  _alert(window.alertMessage[msgKey], { ok: redirect, title: window.alertMessage[titleKey] });
  setTimeout(redirect, 5000); // Redirect automatically if user is sleeping
}

// Sample event (temporary)

// Terminal features
var terminalConfig = getTerminalConfig();

var USER_ID = window.USER_ID;
socket.on("hello", function (who) {
  USER_ID = who.userId;
  window.console && console.log("My name is", who.user); // eslint-disable-line no-console
});

// Vote status
const updateVoteStatus = (status) => {
  const $container = getVoterContainer(),
        $voteToolbar = $container.find('.js-voteToolbar'),
        $allStatus = $container.find('.js-voteStatus'),
        tmp_2052 = $allStatus.filter('[data-status="novote"]').length, // #2052 (RM2-C4) Single/Multiple status + Send button
        showVoteToolbar = tmp_2052 || (!tmp_2052 && status !== 'novote') ? true : false,
        $newStatus = $allStatus.filter('[data-status="' + status + '"]');
  $voteToolbar.toggleClass('active', showVoteToolbar);
  $allStatus
    .removeClass('active')
    .removeAttr('role')
    .attr('aria-hidden', 'true');
  if($newStatus.length) {
    $newStatus[0].offsetWidth; // Reset CSS animation (https://css-tricks.com/restart-css-animation/)
    $newStatus
      .addClass('active')
      .removeAttr('aria-hidden')
      .attr('role', 'alert');
  }
};

// Session update
function updateSessionStatus (instance) {
  // Switching to live: refresh to force counters update
  if (window.SESSION.instance !== 'live' && instance === 'live') {
    document.location.reload(true);
    return;
  }

  window.SESSION.instance = instance;

  // Save terminal config if participant already connected and we are switching instance : test <-> live
  if(instance === 'test' || instance === 'live') {
    socket.emit('participant:terminalConfig', { 'terminalConfig' : terminalConfig, 'instance' : instance }, function (err) {
      if (window.console && console.log) { // eslint-disable-line no-console
        if(err) {
          console.log('terminalConfig save status : ', err); // eslint-disable-line no-console
        } else {
          console.log('terminalConfig status saved'); // eslint-disable-line no-console
        }
      }
    });
  }
  // Session title
  var title = window.SESSION.title;
  if (instance && instance !== "live") {
    title += " [" + instance.toUpperCase() + "]";
  }
  $(".js-sessionTitle").text(title);

  // Disabled session?
  if (instance === "off") {
    socket.close();
    $("#question").attr("disabled", true);
    $(".js-buttonSend").attr("disabled", true);
    showHidePoll(null);
    quitSession('complete');
  } else {
    $("#question").attr("disabled", false);
    var question = $("#question").val() || "";
    $("#send-question").attr("disabled", question.length <= 3);
    // Wait for server which should send us current poll if any
  }
}

updateSessionStatus(window.SESSION.instance);

socket.onConfirmed("session:change-mode", updateSessionStatus);

function updateSessionTitle (title) {
  if (window.SESSION.title === title) return; // Nothing to update here
  window.SESSION.title = title;
  $(".js-sessionTitle").text(title);
}

function updateSessionBegin (begin) {
  if (window.SESSION.begin === begin) return; // Nothing to update here
  window.SESSION.begin = begin;
}

function updateSessionEnd (end) {
  if (window.SESSION.end === end) return; // Nothing to update here
  window.SESSION.end = end;
}

function updateSessionSolicitationText (solicitationText) {
  const $questionTextarea = $('.js-blocSaisieQuestion');
  if ($questionTextarea.attr('placeholder') === solicitationText) return; // Nothing to update here
  $questionTextarea.attr('placeholder', solicitationText);
}

let streamAlertID = '';
function updateSessionStream (opts) {
  if (opts) { // enabled stream
    const o = JSON.parse(opts);
    if (_.isEqual(window.QD_STREAM_OPTIONS, o) && window.QD_STREAM_PLAYER_HTML_CONTENT) { // Same parameters, player should be ready
      streamAlertID = _alertWithID('Souhaitez-vous afficher le stream ?', {
        title: 'Streaming actif pour cette session',
        okLabel: 'Avec stream',
        cancelLabel: 'Sans stream',
        yes: function () { enableStreamPlayer(); },
        no: function () { disableStreamPlayer(); }
      }, 'confirm');
    } else { // Stream parameters differs, reload page
      document.location.reload();
    }
  } else { // no (more?) stream in session
    // We do not reset here QD_STREAM_* variables as they can be reused on a later stream, if any
    disableStreamPlayer();
  }
}

function updateSession (updates) {
  if(!updates) /* todo DELETED */ return;
  if(updates.hasOwnProperty('title')) updateSessionTitle(updates.title); // eslint-disable-line no-prototype-builtins
  if(updates.hasOwnProperty('begin')) updateSessionBegin(updates.begin); // eslint-disable-line no-prototype-builtins
  if(updates.hasOwnProperty('end')) updateSessionEnd(updates.end); // eslint-disable-line no-prototype-builtins
  if(updates.hasOwnProperty('solicitationText')) updateSessionSolicitationText(updates.solicitationText); // eslint-disable-line no-prototype-builtins
  // session:edit event can carry updates on event (accessCode in options or name)
  // but we don't update window.EVENT 'name' or 'options' as they don't exist here
  if(updates.hasOwnProperty('stream_options')) updateSessionStream(updates.stream_options); // eslint-disable-line no-prototype-builtins
}

socket.on("session:edit", updateSession);

// Send question

// UI Input live counter
// TODO: Externalized module
$('.ui-inputCounter').each(function() {

  // Cache elements
  const $this = $(this),
        $input = $($this.attr('data-ui-input')),
        $counter = $this.find('.ui-inputCounter-counter'),
        $button = $($this.attr('data-ui-button')),
        input_maxlength = parseInt($this.attr('data-ui-input-maxlength')) || false,
        input_minlength = parseInt($this.attr('data-ui-input-minlength')) || false,
        input_alertlength = parseInt($this.attr('data-ui-input-alertlength')) || false;

  // Manage live counter & button state
  const manageInputCounter = function() {
    const entry_length = $input.val().length,
          is_entry_valid = (!input_minlength || (entry_length >= input_minlength)) && (!input_maxlength || (entry_length <= input_maxlength)),
          is_max_reached = input_maxlength && (entry_length === input_maxlength),
          is_alert_reached = input_alertlength && (entry_length > input_alertlength);
    $counter.text(entry_length);
    $button.prop('disabled', !is_entry_valid);
    if(is_max_reached) {
      $this.attr('data-ui-input-status', 'max');
    } else if(is_alert_reached) {
      $this.attr('data-ui-input-status', 'alert');
    } else {
      $this.attr('data-ui-input-status', '');
    }
  }

  // At page load
  manageInputCounter();

  // On input
  $input.on('input', function() {
    manageInputCounter();
  });
});

// Fake progress bar when sending question is slow
const QUESTION_SEND_TIMEOUT = 3000;

// Restore previously typed question
var localStoredQuestion = _localStorage.getItem('lap_participant_question');
if (localStoredQuestion) {
  $(".js-blocSaisieQuestion").val(localStoredQuestion);
  _localStorage.removeItem('lap_participant_question');
}

$(document).on("click", ".js-buttonSend:not(.processing)", function (event) {
  event.preventDefault();
  // Mark as processing
  var $this = $(this);
  // Start fake progress
  buttonProgressing.start($this);
  var question = $(".js-blocSaisieQuestion").val();
  // Save current question in localStorage
  _localStorage.setItem('lap_participant_question', $(".js-blocSaisieQuestion").val());
  // After 10 seconds ask for cancel
  let waiting_question_alert_id = '';
  var questionExpirationTimeout = setTimeout(function () {
    waiting_question_alert_id = _alertWithID(window.alertMessage.sendQuestionNetworkErrorMsg, {
      title: window.alertMessage.sendQuestionNetworkErrorTitle,
      reload: true
    });
  }, QUESTION_SEND_TIMEOUT);
  socket.emit("participant:send-question", { question: question }, function (err) {
    // Hide slow message if necessary
    clearTimeout(questionExpirationTimeout);
    if(waiting_question_alert_id !== '') { closeAlert(waiting_question_alert_id); }
    // Reset progress (successfully this time) and wait for end of animation to show result
    buttonProgressing.stop(true, function () {
      if (err) {
        _alert(window.alertMessage.sendQuestionError.replace(/<ERR>/, err));
      } else {
        // on success..
        $(".js-blocSaisieQuestion").val('').trigger('input');
        _localStorage.removeItem('lap_participant_question');
      }
    });
  });
});

// Programmatically update style after rendering user responses
// TODO do this in template directly, and use lodash templates
const postRenderResponse = poll => {
  manageSprite(poll);
  manageFlipFlap(poll);
  manageMaxChoices(poll);
  manageWordCloudFocus(poll);
};

// Enable/disable checkboxes depending on max_choices
const manageMaxChoices = poll => {
  if (poll.style_params && poll.style_params.max_choices && poll.step === 'open') {
    _.each(getVoterIds(poll), voterId => {
      const $container = $(`[data-voter-id=${voterId}]`);
      $('.js-propositionButton input:not(:checked)', $container).each(function () {
        const $this = $(this);
        const responses = getLocalResponses(poll.id, voterId);
        if (responses && responses.length >= poll.style_params.max_choices) {
          $this.prop('disabled', true);
          $this.closest('.js-propositionButton').addClass('disabled');
        } else {
          $this.prop('disabled', false);
          $this.closest('.js-propositionButton').removeClass('disabled');
        }
      });
    });
  }
};

// Poll sprite manage
// TODO Don't do that at page load (already managed in lodash template)
const manageSprite = poll => {

  if (!poll.style_params || poll.style_params.buttonStyle !== 'sprite') {
    return;
  }

  // Vars
  const $container = getVoterContainer(),
        $inputs = $container.find('.js-propositionButton input'),
        $checked = $inputs.filter(':checked');

  // Has vote = make all previous items active
  if($checked.length) {

      // Get index
      const indexOfCurrent = $checked.closest('.js-propositionButton').index();

      // Loop to manage states
      $inputs.each(function(key) {
        const $thisInput = $(this);
        key < indexOfCurrent ?
          $thisInput.addClass('checked') :
          $thisInput.removeClass('checked');
      });

  }
  else { // Unvote (or no vote) = remove active on all buttons
    $inputs.removeClass('checked');
  }

};

// Poll flipflap manage
const manageFlipFlap = poll => {

  if (poll.type !== 'vote' || !poll.style_params.flipflap) {
    return;
  }

  // Vars
  const $container = getVoterContainer(),
        $checked = $container.find('.js-propositionButton input:checked');

  // If vote
  if($checked.length) {

    // Recover current vote
    const vote = $checked.attr('data-proposition-id');

    // Toggle flipflap
    $('.ui-voteFlipFlap').addClass('active');

    //  Reset previous active
    $('.ui-voteFlipFlapProp').removeClass('active');

    // Set current active
    $('.ui-voteFlipFlapProp[data-proposition-id="' + vote + '"]').addClass('active');
  }

};

// Poll handlers
$('#popup-poll')

// Close flipflap mode
.on('click', '.js-closeFlipFlap', function() {
  $('.ui-voteFlipFlap, .ui-voteFlipFlapProp').removeClass('active');
})
// Toggle flipflap proposition
.on('click', '.js-toggleFlipFlapProp', function() {
  const $container = getVoterContainer();
  $container
    .find('.js-propositionButton input:not(:checked)')
    .prop('checked', true)
    .trigger('change');
})

// Wordcloud word validation
.on('input', '.js-wordEntry', function() {
  const $entry = $(this),
        $wrapper = $entry.closest('.js-wordTpl'),
        $btn = $wrapper.find('.js-sendWord');

  const domEntry = $entry.get(0);

  // Ensure lower case
  domEntry.value = domEntry.value.toLowerCase();

  if (typeof domEntry.setCustomValidity === 'function') {
    const $container = $wrapper.closest('.js-wordsList'),
          $voterDisabledInputs = $container.find('.js-wordEntry[disabled]'),
          previousValues = $voterDisabledInputs.map(function () { return this.value; }).get();
    if (_.includes(previousValues, domEntry.value)) {
      domEntry.setCustomValidity('Vous avez déjà saisi ce mot');
    } else {
      domEntry.setCustomValidity('');
    }
  }

  // Live HTML5 validation
  if (typeof domEntry.reportValidity === 'function') {
    domEntry.reportValidity();
  }

  // Manage button state
  $entry.is(':valid') ?
    $btn.prop('disabled', false) :
    $btn.prop('disabled', true);
})

// Wordcloud send word
.on('keypress', '.js-wordEntry', function(e) {
  // Submit by pressing Enter
  if (e.key === 'Enter') {
    $(this).closest('.js-wordTpl').find('.js-sendWord').trigger('click');
  }
})
.on('click', '.js-sendWord', function() {
  const $btn = $(this),
        $wrapper = $btn.closest('.js-wordTpl'),
        $entry = $wrapper.find('.js-wordEntry'),
        $container = getVoterContainer(),
        $add = $container.find('.js-addWord');
  // Check validity and prevent submission if failed
  const domEntry = $entry.get(0);
  if (typeof domEntry.reportValidity === 'function') {
    domEntry.reportValidity();
  }
  if (domEntry.validity && !domEntry.validity.valid) {
    return;
  }
  // Send proposition to server (id = label to dedupe exact same words)
  const voterId = getVoterId();
  emitProposition({ label: domEntry.value, id: domEntry.value }, voterId, true, err => {
    if (!err) {
      // Manage states
      removeFakeProposition(currentPoll); // Disable "new word" field if showHideQCM is called
      $btn.blur().prop('disabled', true);
      $entry.prop('disabled', true);
      $add.prop('disabled', false);
      if (!preview) $add.focus();
    }
  });
})

// Wordcloud add another word
.on('click', '.js-addWord', function() {
  const $add = $(this),
        $container = getVoterContainer(),
        $voteToolbar = $container.find('.js-voteToolbar'),
        $clone = $('.js-wordTpl:first-child', $container).clone();
  // Clean & manage states
  addFakeProposition(currentPoll);
  $clone.find('.js-wordEntry', $container).prop('disabled', false).val('');
  $add.prop('disabled', true);
  // Add clone
  $clone.appendTo($('.js-wordsList', $container));
  $voteToolbar.removeClass('active');
  if (!preview) $clone.find('.js-wordEntry').focus();
});

// Wordcloud: focus to editable word entry
const manageWordCloudFocus = () => {
  if (!preview) {
    const $container = getVoterContainer();
    $container.find('.js-wordEntry:not([disabled])').focus();
  }
};

// Injected in the propositions list to keep the loop simple in template
const addFakeProposition = poll => {
  // Already passed here: ignore
  // (this can happen at startup for example: 1 at load time, 2 when socket connect, 3 when applying watchTheme)
  if (poll.addedFakeProp) {
    return;
  }
  // Wordcloud: inject to display the empty input
  const responses = poll.responses[getVoterId()] || [];
  if (poll.type === 'wordcloud' && (responses.length === 0 || poll.multiple)) {
    poll.addedFakeProp = true;
    poll.propositions.push({ fakeProp: 'addNewWord' });
  }
};
const removeFakeProposition = poll => {
  if (!poll.addedFakeProp) {
    return;
  }
  poll.addedFakeProp = false;
  poll.propositions = poll.propositions.filter(p => !p.fakeProp);
};

// Handle polls
const templatePoll = _.template($("#poll-template").text());
const $popupPoll = $("#popup-poll");
let currentPoll = null;

// Stream

const isStreamPlayerEnabled = () => $('body').attr('data-stream') === '1';

const enableStreamPlayer = () => {
  if (isStreamPlayerEnabled()) return;
  $('body').attr('data-stream', '1'); // Enable player
  $('.js-streamLayout-player').html(window.QD_STREAM_PLAYER_HTML_CONTENT); // Inject player to the left column
  if (currentPoll) { showHidePoll(currentPoll); } // Refresh popup
};

const disableStreamPlayer = () => {
  if(streamAlertID !== '') {
    closeAlert(streamAlertID);
    streamAlertID = '';
  }
  if (!isStreamPlayerEnabled()) return;
  $('body').attr('data-stream', '0'); // Disable player
  $('.js-streamLayout-player').empty(); // Clear left column
  if (currentPoll) { showHidePoll(currentPoll); } // Refresh popup
};

window.enableStreamPlayer = enableStreamPlayer;
window.disableStreamPlayer = disableStreamPlayer;

// Store locally sent responses as they're not sent back until reconnection
const localResponses = {}; // { [pollId] : Array<Number> } }
const getLocalResponses = (pollId, userId) => {
  const pollLocalResponses = localResponses[pollId] || (localResponses[pollId] = {});
  const userLocalResponses = pollLocalResponses[userId] || (pollLocalResponses[userId] = []);
  return userLocalResponses;
};
const initLocalResponses = poll => {
  if (!poll.responses) return;
  const ids = getVoterIds(poll);
  _.each(ids, userId => {
    if (!poll.responses[userId]) return;
    const responses = getLocalResponses(poll.id, userId);
    if (!responses.length) {
      responses.push(...poll.responses[userId]);
    }
  });
};
const initPollResponses = poll => {
  if (!poll.responses) poll.responses = {};
  const ids = getVoterIds(poll);
  _.each(ids, userId => {
    if (!poll.responses[userId]) poll.responses[userId] = getLocalResponses(poll.id, userId);
  });
};

const getVoters = poll => {
  if (!poll.procurations) {
    poll.procurations = _.filter(window.PROCURATIONS || [], p => !p.poll_id || p.poll_id == poll.id);
  }
  if (!poll.voters) {
    poll.voters = []
      .concat(window.ONLY_MANDATARY ? [] : [{
        // Fake procuration object for myself
        mandator_id: USER_ID,
      }])
      .concat(poll.procurations);
  }
  if (!('isMandator' in poll)) {
    poll.isMandator = _.some(window.PROCURATIONS_GIVEN || [], p => !p.poll_id || p.poll_id == poll.id);
  }
  return poll.voters;
};
const getVoterIds = poll => _.map(getVoters(poll), 'mandator_id');

// Show-of-hands polls: track participants votes and status

let voteUsers = []; // Stored and computed vote data

const electorsTemplate = _.template($('#poll-electors-template').text());

const getElectorsHtml = () => electorsTemplate({
  _,
  poll: currentPoll,
  users: voteUsers,
  voteIndex: computeVoteIndex(_, currentPoll.propositions),
});

socket.on('participant:responses', data => {
  if (!currentPoll) return;
  currentPoll.responses = data;
  // Refresh 'voted' statuses
  _.map(voteUsers, u => {
    u.voted[currentPoll.id] = u.id in data;
  });
  // Refresh electors HTML
  $('.js-electorsListContainer').html(getElectorsHtml());
});

socket.on('participant:voters', data => {
  if (!currentPoll) return;
  voteUsers = _.sortBy(_.map(data, ([
    id, present, login, firstname, lastname, status,
    mandataryId, mandataryLogin, mandataryFirstname, mandataryLastname
  ]) => ({
    id,
    voted: { // updated by participant:responses
      [currentPoll.id]: currentPoll.responses && (id in currentPoll.responses),
    },
    present: !!present,
    login: maskEmail(login),
    firstname,
    lastname,
    mandatary: mandataryId && {
      id: mandataryId,
      login: maskEmail(mandataryLogin),
      firstname: mandataryFirstname,
      lastname: mandataryLastname,
    },
    status
  })), ['lastname', 'firstname']);
  // Refresh legends
  const hasProcurations = _.some(voteUsers, u => !!u.mandatary);
  updatePollStatusFilterLabels(hasProcurations);
  // Refresh electors HTML
  $('.js-electorsListContainer').html(getElectorsHtml());
});

socket.on('updated-procuration', () => {
  if (currentPoll) {
    socket.emit('participant:request-voters', currentPoll.id);
  }
});

const subscribeVoters = () => {
  socket.emit('participant:subscribe-voters', currentPoll.id, err => {
    if (err) {
      _toast('Erreur lors de la requête, impossible de récupérer le détail des votes actuellement, merci de rafraîchir la page pour réessayer', { error: true, closable: true });
    }
  });
};

const unsubscribeVoters = () => {
  if (currentPoll) {
    socket.emit('participant:unsubscribe-voters', currentPoll.id);
    $('.js-electorsListContainer').html('');
    voteUsers = [];
  }
};

// Show/hide poll

let lastRefDate = null;
const $main = $('.js-main');
function showHidePoll (poll) {
  if (poll && $popupPoll.length) { // Don't do anything if $popupPoll doesn't exist (ie user is mandator, see 'sessionProcurationGiven' in participant.html)
    initLocalResponses(poll);

    if (poll.refDate && lastRefDate && lastRefDate > poll.refDate) {
      // We receive an expired event, due to race conditions, ignore it
      window.console && console.warn && console.warn('Race condition, ignore showHidePoll', poll); // eslint-disable-line no-console
      return;
    }
    currentPoll = poll;
    lastRefDate = poll.refDate;

    // Merge "style params"
    fillPollStyleParams(poll, window.SESSION);

    // Show poll
    initPollResponses(poll);

    // Additional info shown to user about multiple propositions
    poll.multipleInfo = (poll.type === 'note' || poll.type === 'vote')
      ? null // No additional info for note/vote where it doesn't make any sense
      : (poll.multiple ? 'multiple' : 'single');

    // Inject procurations
    getVoters(poll);

    // Add fake propositions in some case, e.g. when in the middle of propositions list you have to add specific items
    addFakeProposition(poll);

    try {
      if(preview) {
        poll.procurations = [];
        poll.voters = [poll.voters[0]];
        poll.responses = {};
      }
      $popupPoll.html(templatePoll({
        ...poll,
        voteIndex: computeVoteIndex(_, currentPoll.propositions),
        electorsHtml: getElectorsHtml(),
        participants: {
          procurations: window.SESSION_HAS_PROCURATIONS,
        } 
      }));
      // Manage style after rendering response
      postRenderResponse(poll);
    } catch (err) {
      _alert(window.alertMessage.failedRenderingPoll, { reload: true });
      window.console && console.error('Failed rendering poll', err); // eslint-disable-line no-console
      return;
    }

    // Exit potential full-screen element first, to ensure popup will pop over
    // see https://stackoverflow.com/a/51529770
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      // There is an active full-screen element (stream player probably) → exit full screen mode
      // TODO restore full-screen element when hiding poll?
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
    }

    // Stream options
    let magnificpopup_stream_options = {};
    if(window.QD_STREAM_PLAYER_HTML_CONTENT && isStreamPlayerEnabled()) {
      magnificpopup_stream_options = {
        prependTo: $('.js-streamLayout-participant'),
        overflowY: 'hidden',
      };
    }

    // Preview options
    let magnificpopup_preview_options = {};
    if(preview) {
      magnificpopup_preview_options = {
        focus: 'hack_popup_focus', // Avoid iframe stealing focus when moderator configures a new poll (when editing title or proposition)
        overflowY: 'hidden', // Disable scrolling
      };
    }

    // Now open populated popup
    $.magnificPopup.close(); // Fix callbacks not working when popup already open
    $.magnificPopup.open({
      ...magnificpopup_inline_defaults,
      ...magnificpopup_stream_options,
      ...magnificpopup_preview_options,
      alignTop: true,
      modal: true,
      items: {
          src: $popupPoll,
          type: 'inline'
      },
      callbacks: {
        open() {

          // Accessibility: hide main content to screenreaders
          $main.attr('aria-hidden', true);

          // Accessibility: popup attributes
          $.magnificPopup.instance.wrap
            .attr('role', 'dialog')
            .attr('aria-modal', 'true')
            .attr('tabindex', '-1')
            .attr('aria-labelledby', 'popup-poll-title');

          // Init expander
          $('.js-expander', $popupPoll).expander({
            ellipsis: true
          });

          // Init show of hands
          if (currentPoll.style_params && currentPoll.style_params.vote_type === 'showofhands') {
            const $votesDetails = $('.js-votesDetails', $popupPoll);
            // Init accordion (showofhands)
            $votesDetails.simpleAccordion();
            // Watch voters (showofhands)
            $votesDetails.off('simpleAccordion.beforeOpen').on('simpleAccordion.beforeOpen', () => {
              // Open accordion = subscribe
              subscribeVoters();
            });
            $votesDetails.off('simpleAccordion.afterClose').on('simpleAccordion.afterClose', () => {
              // Close accordion = unsubscribe
              unsubscribeVoters();
            });
          }

        },
        close() {
          // Accessibility: recover main content readable by screenreaders
          $main.attr('aria-hidden', false);
        }
      }
    });

  } else {
    // Whenever I was watching showofhands results, stop watching
    unsubscribeVoters();
    voteUsers = [];
    // Forget my responses: poll has been closed or back to 'ready', in both cases
    // next time it's open my responses will have been reset
    if (currentPoll) {
      delete localResponses[currentPoll.id];
      currentPoll = null;
    }
    // Hide if visible (don't close other popups, ie confidentiality or non-anonymous ones)
    if($popupPoll.is(':visible')) { $.magnificPopup.close(); }
  }
}

if (window.CURRENT_POLL) {
  // Force step = 'show' (unless step was already null) to disable inputs unless socket is properly connected
  showHidePoll(_.merge({}, window.CURRENT_POLL, { step: window.CURRENT_POLL.step && 'show' }));
}
socket.onConfirmed("participant:qcm", showHidePoll);

// Ask server for current status (mode, poll, theme)
socket.on("connect", function () {
  socket.emit("participant:current-mode", window.SESSION.instance);
});
socket.io.on("reconnect", function () {
  socket.emit("participant:current-theme");
});

$("#popup-poll")
// Manage screenreaders & keyboard vote
.on("click", '.js-propositionButton input', function (e) {
  // Keep native behavior, do not add 'preventDefault()' or 'return false'
  // stopImmediatePropagation() is a must have to:
  // - avoid bubbling to the following click event
  // - fix issue on refresh where native input behavior is disabled (no idea why)
  e.stopImmediatePropagation();
})
// Manage desktop/mobile vote + allow null state for radio button
.on("click", '.js-propositionButton', function (e) {
  e.preventDefault();
  const $this = $(this),
        $input = $this.find('input'),
        toggleState = $input.is(':checked') ? false : true;
  $input
    .prop('checked', toggleState)
    .trigger('change');
})
// Manage change
.on("change", '.js-propositions input', function (e) {
  const $this = $(e.target);
  // val = "voteId-voterId"
  const [voteId, mandatorId] = $this.val().split('-').map(Number);
  const ok = () => {
    const checked = $this.is(':checked');
    updateVoteStatus('pending');
    // _toast(window.alertMessage.voteWaiting, { timeout: 0, closable: false, id: 'vote-pending' });
    
    const voterId = mandatorId || window.USER_ID;

    emitVote(voteId, checked, voterId, err => {
      if (err) {
        // on error, reset status
        $this.prop('checked', !checked);
      }
    });

    // Optimistically manage style after responses has changed
    postRenderResponse(currentPoll);
  };

  if (!currentPoll || !voteId) {
    _confirm(window.alertMessage.invalidDataOnVote, {
      yes: () => document.reload(true),
      no: ok,
    });
  } else {
    ok();
  }
});

const showVoteError = err => {
  const message = {
    TOO_LATE_POLL_CLOSED: window.alertMessage.voteTooLateError,
    NO_VALID_PROCURATION: window.alertMessage.voteInvalidProcurationError,
    FORBIDDEN_MANDATOR_POLL: window.alertMessage.voteNotAllowedMandatorError,
    NOT_ALLOWED_TO_VOTE_YOURSELF: window.alertMessage.voteNotAllowedYourselfError,
    INVALID_VOTE_MAX_CHOICES: window.alertMessage.voteInvalidMaxChoices,
    // TODO i18n
    NOT_ALLOWED_TO_ADD_PROPOSITION: window.alertMessage.voteError.replace(/<ERR>/, 'Ce sondage n’accepte pas de nouvelle proposition'),
  }[err] || window.alertMessage.voteError.replace(/<ERR>/, err);
  $('.js-voteError').text(message);
  updateVoteStatus('error');
  // _toast(message, { error: true, closable: false, id: 'vote-pending' });
};

// Send new proposition to dynamic poll (like wordcloud)
const emitProposition = (prop, voterId, shouldBeChecked, cb) => {
  socket.emit('participant:poll-proposition', currentPoll.id, prop, voterId, (err, addedProp, isChecked) => {
    if (err) {
      showVoteError(err);
    } else {
      // Manage state: add to poll object
      currentPoll.propositions.push(prop);
    }

    if (!err && addedProp && !shouldBeChecked !== !isChecked) {
      emitVote(addedProp.id, !!shouldBeChecked, voterId, err2 => {
        cb(err2, addedProp);
      });
    } else {
      cb(err, addedProp);
    }
  });
};

// Update participant's vote
const emitVote = (voteId, checked, voterId, cb) => {
  const postEmitVote = err => {
    if (cb) {
      setTimeout(() => cb(err), 0);
    }
    // Re-manage styles with actual real state: required only for max_choices
    if (currentPoll.style_params && currentPoll.style_params.max_choices) {
      postRenderResponse(currentPoll);
    }
  };

  socket.emit("participant:qcm-vote", currentPoll.id, voteId, checked, voterId, function (err) {
    if (err) {
      showVoteError(err);
    }

    if (err) {
      postEmitVote(err);
      return;
    } else {
      // _toast(window.alertMessage.voteSuccess, { timeout: 3000, success: true, closable: false, id: 'vote-pending' });
    }

    // Store locally current user's responses, as server will not send them back
    // in next messages (it's sent only in first message when I ask the poll myself)
    const responses = getLocalResponses(currentPoll.id, voterId);
    if (checked) {
      if (currentPoll.multiple) {
        if (responses.indexOf(voteId) === -1) {
          responses.push(voteId);
        }
      } else {
        responses.splice(0, responses.length, voteId);
      }
    } else {
      const index = responses.indexOf(voteId);
      if (index !== -1) {
        responses.splice(index, 1);
      }
    }

    // UI Procuration tab
    const $procurationTab = $(`.js-voteDone-${voterId}`),
          procurationTab_active = 'item-voteDone';
    if (responses.length > 0) {
      $procurationTab.addClass(procurationTab_active);
    } else {
      $procurationTab.removeClass(procurationTab_active);
    }

    // UI Vote toolbar
    const voteStatus = responses.length ? 'voted' : 'novote';
    updateVoteStatus(voteStatus);

    postEmitVote();
  });
};

$(window).on('load', function () {
  setTimeout(function () {
    $('body').append('<i class="iconpreload iconpoll">&nbsp;</i>');
  }, 2000);
});

// Top bar widgets
const $topBar = $('.js-topBar');
if($topBar.length) {
  // Hide bar
  $('.js-closeTopBar').on('click', function() {
    const $this = $(this),
          $container = $this.closest($topBar);
    $this.blur(); // Remove focus outline
    $container.slideUp();
    return false;
  });
  // Popups
  const $topBarPopupTrigger = $('.js-openTopBarPopup');
  $topBarPopupTrigger.magnificPopup({
    ...magnificpopup_inline_defaults,
    callbacks: {
      beforeOpen() {
        const $trigger = $.magnificPopup.instance.st.el,
              popup_id = $trigger.attr('href'),
              $popup = $(popup_id),
              contentToShow = $trigger.attr('data-popup-content');
        $popup.find('[data-popup-content]').addClass('ui-hidden');
        $popup.find('[data-popup-content="' + contentToShow + '"]').removeClass('ui-hidden');
        $topBarPopupTrigger.blur(); // Remove focus outline
      }
    }
  });
}

// Handle 'data-broadcastor data-hasbroadcastor' attr
broadcastorFlag(socket);

// React to procuration update (if user is concerned)
socket.on('updated-procuration', (mandatorId, mandataryId) => {
  if (mandatorId == window.USER_ID || mandataryId == window.USER_ID) {
    document.location.reload(true);
  }
});

socket.on('participant:reload', ({ delayMin = 0, delayMax = 10000 } = {}) => {
  const delay = Math.round(Math.random() * (delayMax - delayMin) + delayMin);
  setTimeout(() => {
    // TODO warn participant? wait for end of current interaction (like they're writing a question)?
    document.location.reload(true);
  }, delay);
});
