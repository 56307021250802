import $ from 'jquery';

export const updatePollStatusFilterLabels = hasProcurations => {
  $('.js-resultsStatsLegend-voted').text(
    window.ndTranslations.pollUserStatus('voted', hasProcurations ? 1 : 0),
  );
  $('.js-resultsStatsLegend-notvoted').text(
    window.ndTranslations.pollUserStatus('present', hasProcurations ? 1 : 0),
  );
  $('.js-resultsStatsLegend-absents').text(
    window.ndTranslations.pollUserStatus('absent', hasProcurations ? 1 : 0),
  );
};
